import jquery from "jquery";
window.$ = window.jQuery = jquery;

// lazybox
import "lazysizes";

import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
    useClassNames: true,
    initClassName: false,
    animatedClassName: 'animate__animated',
});